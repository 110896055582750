@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "anonymous-pro";
  src: url("https://use.typekit.net/af/d359b5/000000000000000077359719/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/d359b5/000000000000000077359719/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/d359b5/000000000000000077359719/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

body {
  background-color: #f3f3f3;
  font-family: "proxima-nova", sans-serif;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #201b50;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.innerLoader {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  top: 0;
}

.payment-loader {
  width: 48px;
  height: 48px;
  border: 5px solid #201b50;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a {
  cursor: pointer;
}

.addItem-text {
  text-decoration: none;
  text-align: right;
  font-size: 13px;
  color: #5a4cdc;
}

.addItem-text:hover {
  color: #5a4cdc;
}

.w65-px {
  width: 65px;
}

.loaderouter {
  position: absolute;
  top: 50%;
  display: flex;
  background-color: #fff;
  padding: 6px 20px;
  border-radius: 50px;
  left: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
}

@media (max-width: 575px) {
  .loaderouter {
    transform: translate(-50%, -50%);
  }
}

.loaderouter .sectionLoading {
  margin-left: 10px;
  font-size: 13px;
  color: #00254d;
}

.h-100vh {
  height: 100vh;
}

.h-80vh {
  height: 80vh;
}

.heading {
  text-align: left;
}

.heading h2 {
  color: #201b50;
  font-size: 26px;
  font-weight: 600;
}

.pl-240 {
  padding-left: 240px;
}

@media (max-width: 991px) {
  .pl-240 {
    padding-left: 15px;
  }
}

.pr-80 {
  padding-right: 80px;
}

.commonPadding {
  padding: 35px 0;
}

.horizontalPadding {
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 575px) {
  .horizontalPadding {
    padding-left: 0;
    padding-right: 0;
  }
}

.pill {
  padding: 4px 6px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
}

.pill.pill-grey {
  color: #6b778c;
  background-color: #f3f3f7;
}

.pill.pill-green {
  background-color: #d8f6df;
  color: #28a745;
}

.pill.pill-orange {
  color: #fdbf33;
  background-color: #ffefcb;
}

.pill.pill-blue {
  color: #007bff;
  background-color: #cce5ff;
}

.pill.pill-red {
  color: #f00;
  background-color: rgba(255, 0, 0, 0.3);
}

.fs-12 {
  font-size: 12px;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.circle.circle-warning {
  background-color: #ffc107;
}

.circle.circle-spam {
  background-color: #6b778c;
}

.circle.circle-success {
  background-color: #28a745;
}

.dropdown .dropdown-toggle {
  background-color: transparent;
  border: none;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown .dropdown-toggle:hover {
  background-color: transparent;
}

.dropdown .dropdown-menu {
  border: none;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
}

.dropdown .dropdown-menu li a {
  color: #201b50 !important;
  font-size: 14px;
}

.dropdown .dropdown-menu li a:focus {
  background-color: #e9ecef;
}

.section-filter {
  text-align: right;
}

.section-filter .btn-import {
  background-color: #fff;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
  font-size: 14px;
  border-radius: 10px;
}

.section-filter .btn-import:hover {
  background-color: #fff;
}

.section-filter .btn-import:hover {
  border-color: #fff;
}

.section-filter .add-btn {
  background-color: #5a4cdc;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}

.section-filter .add-btn:hover {
  background-color: #7b6fe2;
}

.section-filter .add-btn.ticketAdd-btn {
  padding: 7px 10px;
  border-radius: 10px;
}

.section-filter .dropdown-toggle {
  font-size: 14px;
}

.section-filter .dropdown-toggle::after {
  display: none;
}

.section-filter .dropdown-toggle.btn-filter {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
  margin-right: 10px;
  padding: 7px 12px;
  border-radius: 10px;
}

.section-filter .dropdown-toggle.btn-filter:hover {
  background-color: #7b6fe2;
  border-color: #7b6fe2;
}

.section-filter .dropdown-toggle.btn-info {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #42526e;
  border-radius: 10px;
}

.section-filter .dropdown-menu {
  border: none;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.section-filter .dropdown-menu li .dropdown-item {
  font-size: 14px;
  color: #00254d;
}

.section-filter .dropdown-menu.filter-drowdown {
  width: 300px;
  padding: 10px;
}

.section-filter .dropdown-menu.filter-drowdown .filter-btns {
  text-align: right;
  margin-bottom: 10px;
}

.section-filter .dropdown-menu.filter-drowdown .filter-btns .btn-clear {
  background: #f3f3f7;
  border: 1px solid #f3f3f7;
  color: #42526e;
  font-size: 12px;
  margin-right: 5px;
  border-radius: 10px;
}

.section-filter .dropdown-menu.filter-drowdown .filter-btns .btn-update {
  background: #5a4cdc;
  border: 1px solid #5a4cdc;
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
}

.section-filter .dropdown-menu.filter-drowdown .filter-btns .btn-update:hover {
  background-color: #7b6fe2;
  border-color: #7b6fe2;
}

.section-filter .dropdown-menu.filter-drowdown .form-control {
  font-size: 13px;
  margin-bottom: 10px;
  color: #201b50;
  border-bottom: 1px solid #c9ced6;
  border-radius: 3px;
  padding: 4px 6px;
  box-shadow: none;
  cursor: pointer;
  background: url(../img/arrow-down-blue.svg);
  background-size: 9px;
  background-position: 96% 50%;
  background-repeat: no-repeat;
}

.section-filter .dropdown-menu.filter-drowdown .form-control option {
  text-transform: capitalize;
}

.section-filter .dropdown-menu .assignedUser {
  border: 1px solid #ced4da;
  position: relative;
  padding: 4px 6px;
  border-radius: 3px;
  margin-bottom: 10px;
  background: url(../img/arrow-down-blue.svg);
  background-size: 9px;
  background-position: 96% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.section-filter .dropdown-menu .assignedUser p {
  color: #201b50;
  font-size: 13px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.section-filter .dropdown-menu .assignedUser ul {
  padding: 0;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 100%;
  margin: 0;
  background-color: #fff;
  border-radius: 0.25rem;
  z-index: 11;
  top: 30px;
  left: 0;
  display: none;
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: auto;
}

.section-filter .dropdown-menu .assignedUser ul::-webkit-scrollbar {
  width: 5px;
}

.section-filter .dropdown-menu .assignedUser ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.section-filter .dropdown-menu .assignedUser ul::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
}

.section-filter .dropdown-menu .assignedUser ul.active {
  display: block;
}

.section-filter .dropdown-menu .assignedUser ul li {
  list-style-type: none;
  color: #00254d;
  font-size: 13px;
  cursor: pointer;
  line-height: 15px;
}

.section-filter .dropdown-menu .assignedUser ul li small {
  font-size: 12px;
  color: #a5a5a5;
}

.section-filter .dropdown-menu .assignedUser ul li a {
  padding: 8px 22px;
  position: relative;
}

.section-filter .dropdown-menu .assignedUser ul li a span.check-mark {
  position: absolute;
  left: 3px;
  font-size: 13px;
  top: 7px;
}

.section-filter .dropdown-menu .Anyclient p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.section-filter .dropdown-menu .Anyclient ul li .dropdown-item {
  position: relative;
}

.section-filter .dropdown-menu .Anyclient ul li .dropdown-item span {
  position: absolute;
  left: 3px;
  font-size: 13px;
  top: 7px;
}

.dateBetween-filter .form-control {
  background-image: none !important;
}

.section-back a {
  text-decoration: none;
  color: #6b778c;
  font-size: 14px;
}

.commonForm .form-group {
  margin-bottom: 20px;
}

.commonForm .form-group.itemGroup label {
  text-transform: uppercase;
  font-weight: 400;
  color: #6b778c;
}

.commonForm .form-group label {
  color: #201b50;
  display: inline-block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.commonForm .form-group label.checkbox-label {
  font-weight: 400;
}

.commonForm .form-group code {
  color: #00254d;
  font-size: 14px;
}

.commonForm .form-group .logHead {
  color: #6b778c;
  margin: 0;
  font-size: 14px;
}

.commonForm .form-group .logsData {
  color: #201b50;
  font-size: 14px;
}

.commonForm .form-group .form-control {
  box-shadow: none;
  font-size: 14px;
  height: 40px;
  border-radius: 10px;
}

@media (max-width: 575px) {
  .commonForm .form-group .form-control.mobileSpace {
    margin-bottom: 15px;
  }
}

.commonForm .form-group textarea {
  min-height: 100px;
}

.commonForm .form-group .lp-copy-sel__control {
  height: 40px;
  border-radius: 10px;
}

.commonForm .form-group input[type="checkbox"] {
  margin-right: 5px;
}

.commonForm .form-group select.form-control {
  background: url(../img/arrow-down-blue.svg);
  background-size: 11px;
  background-position: 98% 50%;
  background-repeat: no-repeat;
  color: #00254d;
}

.commonForm .form-group .css-1s2u09g-control {
  border-radius: 10px;
}

.commonForm .form-group small {
  color: #6b778c;
  font-size: 12px;
  font-weight: 500;
}

.commonForm .form-group .preview {
  margin-bottom: 10px;
}

.commonForm .form-group .preview img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.commonForm .form-group .d-flex .btn-default {
  background-color: #f3f3f7;
  border: 1px solid #f3f3f7;
  color: #42526e;
  margin-right: 5px;
  font-size: 14px;
  position: relative;
}

@media (max-width: 1440px) {
  .commonForm .form-group .d-flex .btn-default {
    font-size: 13px;
    padding: 4px 6px;
  }
}

.commonForm .form-group .d-flex .btn-default input {
  position: absolute;
  left: 0;
  z-index: 1;
  opacity: 0;
  width: 100%;
}

.commonForm .form-group input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
}

.commonForm .form-group.checkbox-group {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.commonForm .form-group.checkbox-group input[type="checkbox"] {
  vertical-align: unset;
}

.commonForm .form-group.profilePassword {
  position: relative;
}

.commonForm .form-group.profilePassword .form-control {
  background: #f0f0f0;
}

.commonForm .form-group.profilePassword .groupIcon {
  position: absolute;
  top: 40px;
  right: 10px;
  color: #201b50;
  cursor: pointer;
}

.commonForm .form-group.profilePhotoSection .d-flex {
  gap: 10px;
}

.commonForm .form-group.profilePhotoSection .d-flex button {
  height: 35px;
  border-radius: 10px;
}

.commonForm .form-group .my-react-select .css-14el2xx-placeholder {
  font-size: 14px;
}

.commonForm .form-group .my-react-select .css-1s2u09g-control {
  min-height: 40px !important;
  border-radius: 10px;
  margin-bottom: 5px;
}

.commonForm .form-group .my-react-select .css-1s2u09g-control:focus {
  border-width: 1px;
}

.commonForm .form-group .lp-copy-sel__value-container.lp-copy-sel__value-container--has-value.css-1d8n9bt {
  line-height: 16px;
  font-size: 14px;
}

.commonForm .expiryDate-field {
  position: relative;
  z-index: 111;
}

.table-bottom-option .input-group .form-control {
  border-color: #fff;
  font-size: 14px;
  box-shadow: none;
  background-image: url(../img/arrow-down-blue.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 98% 50%;
  cursor: pointer;
}

.table-bottom-option .input-group .btn-apply {
  background-color: #fff;
  border-left: 1px solid #f7f7f7;
  color: #6f798b;
}

.table-bottom-option .input-group .btn-apply:focus {
  border-color: #fff;
  border-left: 1px solid #f7f7f7;
}

.searchFilter .form-control {
  width: 250px;
  margin-left: auto;
}

.table tr th,
.table tr td {
  vertical-align: middle;
}

.table tr th input[type="checkbox"],
.table tr td input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.5);
  border: none;
}

.dataDropdown {
  padding: 0;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  max-height: 260px;
  overflow: auto;
}

.dataDropdown::-webkit-scrollbar {
  width: 5px;
}

.dataDropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dataDropdown::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.dataDropdown li {
  padding: 5px;
  list-style-type: none;
  cursor: pointer;
  font-size: 14px;
}

.dataDropdown li:hover {
  background-color: #f5f5f5;
}

.dataDropdown li span {
  font-size: 12px;
  color: #afafaf;
}

.dataDropdown.AddDropdown {
  z-index: 999;
}

.fw-500 {
  font-weight: 500;
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .p55-large {
    padding: 0 55px;
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .p45-large {
    padding: 0 45px;
  }
}

.sectionPagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 575px) {
  .sectionPagination {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    font-size: 14px;
  }
}

.sectionPagination .paginate {
  float: none;
  margin-bottom: 10px;
}

.sectionPagination select {
  border: 1px solid #888;
  width: 60px;
  height: 33px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .sectionPagination select {
    width: 50px;
    height: 27px;
    font-size: 13px;
  }
}

@media (max-width: 575px) {
  .sectionPagination .page-item .page-link {
    font-size: 13px;
  }
}

.lp-copy-sel__control--is-focused {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.css-319lph-ValueContainer .css-6j8wv5-Input {
  margin: 0;
  padding: 0;
}

.css-319lph-ValueContainer .lp-copy-sel__single-value {
  line-height: 16px;
  font-size: 14px;
}

.ticketForm .relatedOrder .css-b62m3t-container {
  z-index: 11;
}

.ticketForm .ccGroup .css-b62m3t-container {
  z-index: 111;
}

.ticketForm
.relatedOrder
.my-react-select.my-react-select--compact.css-b62m3t-container {
  z-index: 99;
}

.cardNumber-section {
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 10px;
  box-shadow: none;
  height: 40px;
  background-color: #fff;
  font-size: 14px;
}

@media (max-width: 1366px) {
  .cardNumber-section {
    height: 33px;
  }
}

.noData-section {
  text-align: center;
}

.noData-section p {
  margin: 0;
}

.noData-section img {
  width: 100%;
  max-width: 150px;
}

.activeProgress {
  display: flex;
  align-items: center;
}

.attachmentIcon {
  color: #201b50;
}

.delIcon {
  color: #201b50;
}

.section-sermissionDenied {
  text-align: center;
}

.section-sermissionDenied h1 {
  font-weight: 600;
  color: #00254d;
}

.section-sermissionDenied p {
  color: #777;
}

.section-sermissionDenied a {
  background-color: #5a4cdc;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.section-sermissionDenied a:hover {
  background-color: #4c3dd9;
  border-color: #4c3dd9;
}

@media (max-width: 575px) {
  .section-login .h-100vh {
    height: auto;
  }
}

@media (max-width: 991px) {
  .section-login .formOrder {
    order: 2;
  }
}

.section-login .loginForm {
  padding: 20px;
}

@media (max-width: 575px) {
  .section-login .loginForm {
    padding: 0;
  }
}

.section-login .loginForm a.brandName {
  color: #201b50;
  font-weight: 700;
  margin: 0;
  width: 100%;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-size: 25px;
}

.section-login .loginForm h3 {
  margin-top: 30px;
  color: #201b50;
  font-size: 25px;
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 575px) {
  .section-login .loginForm h3 {
    font-size: 21px;
  }
}

.section-login .loginForm form .form-floating {
  position: relative;
}

.section-login .loginForm form .form-floating .form-control {
  height: 48px;
  box-shadow: none;
  border-radius: 10px;
}

.section-login .loginForm form .form-floating .form-control:focus {
  border-color: #ced4da;
}

.section-login .loginForm form .form-floating label {
  font-size: 14px;
  padding-top: 13px;
  opacity: 0.6;
}

.section-login .loginForm form .form-floating .eyeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: #201b50;
}

.section-login .loginForm form .form-floating small.error {
  color: #f00;
}

.section-login .loginForm form .input-group .btn-submit {
  width: 100%;
  height: 48px;
  background-color: #5a4cdc;
  border-color: #5a4cdc;
}

.section-login .loginForm form .input-group .btn-submit:hover {
  background-color: #4c3dd9;
  border-color: #4c3dd9;
}

.section-login .loginForm form.formLogin {
  margin-top: 30px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
  .section-login .loginForm form.formLogin {
    margin-top: 10px;
  }
}

.section-login .loginForm form.formLogin h3 {
  margin: 0;
  background: #201b50;
  color: #fff;
  padding: 15px 0;
  border-radius: 5px 5px 0 0;
}

@media (max-width: 575px) {
  .section-login .loginForm form.formLogin h3 {
    padding: 10px 0;
  }
}

.section-login .loginForm form.formLogin .formInner {
  padding: 30px 30px;
}

.section-login .loginForm .forgotLink {
  text-decoration: none;
  color: #201b50;
  font-size: 13px;
}

.section-login .loginForm .forgotLink.btn-backSignin {
  background-color: #5a4cdc;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
}

.section-login .loginForm .forgotLink.btn-backSignin:hover {
  background-color: #4c3dd9;
  border-color: #4c3dd9;
}

.section-login .loginForm .copyrightText {
  text-align: center;
  margin: 10px 0 0 0;
  font-size: 13px;
  color: #000;
  opacity: 0.5;
}

.section-login .loginForm.logoutLayout p img {
  width: 100%;
  max-width: 30px;
  margin-right: 10px;
}

.section-login .loginImg {
  text-align: center;
}

@media (max-width: 575px) {
  .section-login .loginImg {
    margin: 20px 0;
  }
}

.section-login .loginImg img {
  width: 100%;
  max-width: 80%;
}

@media (max-width: 991px) {
  .section-login .loginImg img {
    max-width: 50%;
  }
}

.section-login .loaderText {
  font-size: 12px;
}

.section-login .sectionalert {
  background-color: #fff8e5;
  font-size: 14px;
  border-left: 4px solid #ffa500;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

.section-login .sectionalert p {
  color: #6c757d;
  margin: 0;
}

.sectionSidebar {
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  left: 0;
  width: 230px;
  padding: 0;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  z-index: 100;
  background-color: #201b50;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  padding: 15px;
}

.sectionSidebar .sidebarInner {
  position: relative;
  overflow: auto;
}

.sectionSidebar .sidebarInner::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.sectionSidebar .sidebarInner::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.sectionSidebar .sidebarInner::-webkit-scrollbar-thumb {
  background-color: rgba(197, 191, 249, 0.5);
  border-radius: 10px;
}

.sectionSidebar .sidebarInner .iconCross {
  position: absolute;
  top: -10px;
  right: 0;
  display: none;
}

@media (max-width: 991px) {
  .sectionSidebar .sidebarInner .iconCross {
    display: block;
    top: 0;
    right: 10px;
  }
}

.sectionSidebar .sidebarInner .iconCross i {
  color: #fff;
}

.sectionSidebar .sidebarInner h4 {
  text-align: left;
}

.sectionSidebar .sidebarInner h4 a {
  text-decoration: none;
  color: #e6f2ff;
  font-weight: 700;
  padding: 1rem 0;
  text-align: left;
  width: 100%;
  font-size: 18px;
}

.sectionSidebar .sidebarInner h4 a:hover {
  color: #fff;
}

.sectionSidebar .sidebarInner ul {
  padding: 0;
}

.sectionSidebar .sidebarInner ul li {
  display: block;
  list-style-type: none;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin-bottom: 5px;
}

.sectionSidebar .sidebarInner ul li.menuHeading {
  margin: 20px 0 10px;
  text-transform: uppercase;
}

.sectionSidebar .sidebarInner ul li a {
  color: #e6f2ff;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
  display: block;
  font-size: 16px;
  border-radius: 4px;
}

.sectionSidebar .sidebarInner ul li a span {
  opacity: 0.7;
  margin-right: 10px;
  width: 21px;
  display: inline-block;
}

.sectionSidebar .sidebarInner ul li a span img {
  width: 100%;
}

.sectionSidebar .sidebarInner ul li a:hover {
  background-color: #494479;
}

.sectionSidebar .sidebarInner ul li.active a {
  background-color: #494479;
}

@media (max-width: 991px) {
  .sectionSidebar {
    transform: translate(-230px, 0);
  }
}

.sectionSidebar.sidebarActive {
  transform: translate(0, 0);
}

.topbar {
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid #ebecf0;
  padding: 12px 0 12px 240px;
  position: relative;
}

@media (max-width: 991px) {
  .topbar {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .topbar .search-parent {
    display: flex;
  }
}

.topbar .hamburger {
  display: none;
}

@media (max-width: 991px) {
  .topbar .hamburger {
    display: block;
  }
}

@media (max-width: 575px) {
  .topbar .hamburger {
    margin-right: 5px;
  }
}

.topbar .hamburger img {
  width: 100%;
  max-width: 25px;
}

.topbar .form-group {
  position: relative;
}

.topbar .form-group .form-control {
  width: 300px;
  padding-left: 26px;
  border: 0;
  font-size: 14px;
}

@media (max-width: 991px) {
  .topbar .form-group .form-control {
    width: 250px;
  }
}

@media (max-width: 575px) {
  .topbar .form-group .form-control {
    width: 100%;
  }
}

.topbar .form-group span {
  position: absolute;
  top: 6px;
  left: 6px;
  color: #b3bac5;
}

.topbar .sidemenus ul {
  padding: 0;
  text-align: right;
  margin: 0;
}

.topbar .sidemenus ul li {
  list-style-type: none;
  display: inline-block;
  margin: 0 15px;
}

.topbar .sidemenus ul li a {
  color: #8f99a9;
  text-decoration: none;
}

.topbar .sidemenus ul li .dropdown button {
  background-color: transparent;
  border: none;
  padding: 0;
  color: #8f99a9;
}

.topbar .sidemenus ul li .dropdown button::after {
  display: none;
}

.topbar .sidemenus ul li .dropdown button img {
  width: 100%;
  max-width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.topbar .sidemenus ul li .dropdown button img.arrowImg {
  width: 13px;
  height: 13px;
  opacity: 0.5;
}

.topbar .sidemenus ul li .dropdown .dropdown-menu li {
  text-align: left;
  display: block;
  margin: 0;
}

.topbar .sidemenus ul li .dropdown .dropdown-menu li a {
  display: block;
  padding: 5px 10px;
  color: #00254d;
  font-size: 14px;
}

.topbar .sidemenus ul li .dropdown .dropdown-menu li a:hover {
  background: #5a4cdc;
  color: #fff !important;
}

.topbar .sidemenus ul li .dropdown .dropdown-menu li:first-child a {
  border-radius: 0.375rem 0.375rem 0 0;
}

.topbar .sidemenus ul li .dropdown .dropdown-menu li:last-child a {
  border-radius: 0 0 0.375rem 0.375rem;
}

.topbar .sidemenus ul li .dropdown .dropdown-menu hr {
  margin: 5px 0;
}

.orders-section .common-tabs {
  margin-top: 10px;
}

.orders-section .common-tabs .nav .nav-item .nav-link {
  color: #6b778c;
  outline: none;
  font-size: 14px;
  padding: 10px 10px;
}

@media (max-width: 1536px) {
  .orders-section .common-tabs .nav .nav-item .nav-link {
    margin-bottom: 10px;
  }
}

@media (max-width: 1440px) {
  .orders-section .common-tabs .nav .nav-item .nav-link {
    padding: 5px 10px;
  }
}

@media (max-width: 991px) {
  .orders-section .common-tabs .nav .nav-item .nav-link {
    padding: 5px 9px;
  }
}

@media (max-width: 575px) {
  .orders-section .common-tabs .nav .nav-item .nav-link {
    padding: 5px 7px;
  }
}

.orders-section .common-tabs .nav .nav-item .nav-link span {
  color: #6b778c;
  background: #f3f3f7;
  padding: 0.25em 0.4em;
  margin-right: 6px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 600;
}

.orders-section .common-tabs .nav .nav-item .nav-link.active {
  border-bottom: 2px solid #5a4cdc;
  background-color: transparent;
  border-radius: 0;
}

.orders-section .common-tabs .nav .nav-item .nav-link.active span {
  color: #5a4cdc;
  background-color: #c5bff9;
}

.orders-section .common-tabs .tab-content .tab-pane {
  outline: none;
}

@media (max-width: 767px) {
  .orders-section .common-tabs.orderTabs .nav-pills {
    display: block;
    white-space: nowrap;
    overflow: auto;
  }
  .orders-section .common-tabs.orderTabs .nav-pills .nav-item {
    display: inline-block;
  }
}

.orders-section .common-table {
  background-color: #fff;
}

.orders-section .common-table .table.logs-table tr td a {
  text-decoration: none;
  color: #6b778c;
}

.orders-section .common-table .table.logs-table tr td a.log-link {
  color: #201b50;
}

.orders-section .common-table .table tr th,
.orders-section .common-table .table tr td {
  text-align: left;
  color: #6b778c;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.81rem;
  padding: 0.75rem;
}

.orders-section .common-table .table tr th a,
.orders-section .common-table .table tr td a {
  text-decoration: none;
  color: #201b50;
}

.orders-section .common-table .table tr th .orderStatus,
.orders-section .common-table .table tr td .orderStatus {
  font-size: 12px;
}

.orders-section .common-table .table tr th .form-group,
.orders-section .common-table .table tr td .form-group {
  display: block;
}

.orders-section .common-table .table tr th .clientSection,
.orders-section .common-table .table tr td .clientSection {
  display: flex;
  gap: 10px;
}

.orders-section .common-table .table tr th .clientSection .img img,
.orders-section .common-table .table tr td .clientSection .img img {
  width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.orders-section .common-table .table tr th .clientSection .clientName p,
.orders-section .common-table .table tr td .clientSection .clientName p {
  margin: 0;
  text-transform: capitalize;
}

.orders-section .common-table .table tr th .clientSection .clientName p a,
.orders-section .common-table .table tr td .clientSection .clientName p a {
  color: #201b50;
  font-size: 14px;
  text-decoration: none;
}

.orders-section .common-table .table tr th .clientSection .clientName p.clientEmail,
.orders-section .common-table .table tr td .clientSection .clientName p.clientEmail {
  text-transform: none;
}

.orders-section .common-table .table tr th .ticketDetail p,
.orders-section .common-table .table tr td .ticketDetail p {
  margin-bottom: 0;
  font-weight: 400;
}

.orders-section .common-table .table tr th .ticketDetail p a,
.orders-section .common-table .table tr td .ticketDetail p a {
  color: #201b50;
  text-decoration: none;
  font-weight: 600;
}

.orders-section .common-table .table tr th .ticketDetail p a.ticketClientEmail,
.orders-section .common-table .table tr td .ticketDetail p a.ticketClientEmail {
  text-transform: none;
}

.orders-section .common-table .table tr th .ticketDetail p span,
.orders-section .common-table .table tr td .ticketDetail p span {
  font-size: 11px;
}

.orders-section .common-table .table tr th .serviceDescription p,
.orders-section .common-table .table tr td .serviceDescription p {
  margin: 0;
}

.orders-section .common-table .table tr th .dropdown .dropdown-toggle i,
.orders-section .common-table .table tr td .dropdown .dropdown-toggle i {
  color: #6d788d;
}

.orders-section .common-table .table tr td {
  text-transform: capitalize;
}

.orders-section .common-table .table tr .clientDetail p {
  margin-bottom: 0;
}

.orders-section .common-table .table tr .clientDetail p a {
  text-decoration: none;
  color: #201b50;
  font-weight: 600;
}

.orders-section .common-table .table tr .clientDetail p span {
  background-color: #f3f3f7;
  font-size: 11px;
  color: #6b778c;
  font-weight: 400;
  padding: 2px 5px;
  border-radius: 5px;
}

.orders-section .common-table .table tr .clientDetail p span i {
  margin-right: 5px;
}

.orders-section .common-table .table tr .clientDetail p.subject a {
  color: #6b778c;
  font-weight: 400;
  text-transform: capitalize;
}

.orders-section .common-table .table tr .assignedImg img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: -8px;
}

.orders-section .common-table .table tr:last-child td {
  border: none;
}

@media (max-width: 767px) {
  .orders-section .common-table.invoiceTable {
    overflow: auto;
  }
}

.orders-section .pagination {
  margin-top: 15px;
}

@media (max-width: 575px) {
  .orders-section .pagination {
    margin: 0;
    display: inline-flex;
    flex-wrap: wrap;
  }
}

.orders-section .pagination .page-item .page-link {
  color: #201b50;
}

.orders-section .pagination .page-item .page-link:hover {
  background-color: #201b50;
  color: #fff;
  border-color: #201b50;
}

.orders-section .pagination .page-item .page-link:focus {
  box-shadow: none;
}

.orders-section .pagination .page-item.active .page-link {
  background-color: #201b50;
  border-color: #201b50;
  color: #fff;
}

.add-order-modals .modal-header .modal-title {
  color: #201b50;
  font-weight: 600;
  font-size: 17px;
}

.add-order-modals .modal-header .btn-close {
  background-size: 11px;
  box-shadow: none;
}

.add-order-modals .add-order-form .add-alert {
  background-color: #fff8e2;
  border-left: 6px solid #ffc107;
  color: #6b778c;
  border: 0.25rem 0 0 0.25rem;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
}

.add-order-modals .add-order-form .add-alert p {
  font-size: 14px;
  margin: 0;
}

.add-order-modals .add-order-form form .form-group {
  text-align: left;
  margin-bottom: 10px;
}

.add-order-modals .add-order-form form .form-group label {
  color: #201b50;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.add-order-modals .add-order-form form .form-group .form-control {
  color: #00254d;
  font-size: 14px;
  box-shadow: none;
}

.add-order-modals .add-order-form form .form-group .form-control:focus {
  border-color: #ced4da;
}

.add-order-modals .add-order-form form .form-group .css-1s2u09g-control {
  border-radius: 10px;
}

.add-order-modals .add-order-form form .form-group select {
  background: url(../img/arrow-down-blue.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 97% 50%;
}

.add-order-modals .add-order-form form .form-group .upload-file {
  text-align: center;
  border: 2px dashed #d7dbe3;
  position: relative;
  padding: 20px;
}

.add-order-modals .add-order-form form .form-group .upload-file i {
  color: #d7dbe3;
}

.add-order-modals .add-order-form form .form-group .upload-file p {
  font-size: 14px;
  color: #6b778c;
  margin: 0;
}

.add-order-modals .add-order-form form .form-group .upload-file p span {
  color: #007bff;
}

.add-order-modals .add-order-form form .form-group .upload-file .inputfile {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.add-order-modals .add-order-form form .form-group small {
  color: #6b778c;
  font-size: 13px;
}

.add-order-modals .add-order-form form .form-group small a {
  text-decoration: none;
}

.add-order-modals .add-order-form form .form-group .lp-copy-sel__control {
  height: 40px;
  border-radius: 10px;
}

.add-order-modals .add-order-form form .form-group .lp-copy-sel__single-value.css-qc6sy-singleValue {
  line-height: 16px;
  font-size: 14px;
}

.add-order-modals .modal-footer button {
  font-size: 14px;
  border-radius: 10px;
}

.add-order-modals .modal-footer button.close-btn {
  background-color: #f3f3f7;
  border-color: #f3f3f7;
  color: #42526e;
}

.add-order-modals .modal-footer button.btn-primary {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
}

.add-order-modals .modal-footer button.btn-primary:hover {
  background-color: #7b6fe2;
  border-color: #7b6fe2;
}

.add-order-modals.newTicket-modal .add-order-form .form-group {
  margin-bottom: 15px;
}

.add-order-modals.newTicket-modal .add-order-form .form-group label {
  color: #201b50;
  display: inline-block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.add-order-modals.newTicket-modal .add-order-form .form-group .form-control {
  box-shadow: none;
  border-radius: 10px;
}

.add-order-modals.newTicket-modal .add-order-form .form-group .relatedOrder {
  z-index: 11;
}

@media (max-width: 767px) {
  .viewOrders.horizontalPadding {
    padding: 0;
  }
}

.viewOrders .sectionHeading {
  margin-bottom: 20px;
}

.viewOrders .sectionHeading h3 {
  font-size: 21px;
  color: #201b50;
  font-weight: 600;
  margin-bottom: 20px;
}

.viewOrders .sectionHeading h3 span.editHeading {
  background: #fafafd;
  border: 1px solid #fafafd;
  color: #42526e;
  display: inline-block;
  font-size: 0.81rem;
  padding: 6px 8px;
  border-radius: 3px;
  opacity: 0;
  transform: translate(-5px);
  transition: all 0.2s linear;
  cursor: pointer;
  margin-left: 5px;
}

.viewOrders .sectionHeading h3:hover .editHeading {
  opacity: 1;
  transform: translate(0);
}

.viewOrders .teamNote {
  border-left: 3px solid #ffc107;
  background: #fff;
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 15px;
  position: relative;
}

.viewOrders .teamNote p {
  margin: 0;
  font-size: 14px;
}

.viewOrders .teamNote a {
  text-decoration: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #201b50;
}

.viewOrders .teamNote .form-group textarea {
  border: none;
  box-shadow: none;
  resize: none;
}

.viewOrders .teamNote .form-group .btn-save-note {
  font-size: 14px;
  margin-top: 10px;
}

.viewOrders .projectData .sectionHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewOrders .projectData .sectionHeading .dropdown-toggle {
  background: #fff;
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #333;
}

.viewOrders .projectData .projectdataContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.viewOrders .projectData .projectdataContent p a {
  text-decoration: none;
}

.viewOrders .projectData .projectdataContent .btnShowmore {
  display: block;
  text-align: center;
  text-decoration: none;
  border-top: 1px solid #5a4cdc;
  padding: 6px 0 0 0;
  color: #201b50;
}

.viewOrders .projectData .projectdataContent .questionSection h3 {
  color: #201b50;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}

.viewOrders .projectData .projectdataContent .questionSection p {
  color: #201b50;
}

.viewOrders .projectData .projectdataContent .questionSection img {
  margin-bottom: 10px;
}

.viewOrders .sectionMessage h3 {
  font-size: 21px;
  color: #201b50;
  font-weight: 600;
  margin-bottom: 20px;
}

.viewOrders .sectionMessage .clientMessage {
  background-color: #fff;
  padding: 20px;
  box-shadow: inset 3px 0 0 #b3bac5;
  border-bottom: 1px solid #ddd;
}

.viewOrders .sectionMessage .clientMessage:last-child {
  border-bottom: none;
}

.viewOrders .sectionMessage .clientMessage.grey {
  box-shadow: inset 3px 0 0 #b3bac5;
}

.viewOrders .sectionMessage .clientMessage.yellow {
  box-shadow: inset 3px 0 0 #ffc107;
}

.viewOrders .sectionMessage .clientMessage.white {
  box-shadow: inset 3px 0 0 #fff;
}

.viewOrders .sectionMessage .clientMessage .messageUser {
  display: flex;
  align-items: center;
  gap: 15px;
}

.viewOrders .sectionMessage .clientMessage .messageUser .userImg img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.viewOrders .sectionMessage .clientMessage .messageUser .userName h4 {
  font-weight: 600;
  font-size: 15px;
}

.viewOrders .sectionMessage .clientMessage .messageUser .userName h4.client {
  color: #6b778c;
}

.viewOrders .sectionMessage .clientMessage .messageUser .userName h4.team {
  color: #ffc107;
}

.viewOrders .sectionMessage .clientMessage .messageUser .userName h4 .messageStatus {
  background: #00254d;
  color: #fff;
  font-weight: 400;
  margin-left: 5px;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 5px;
}

.viewOrders .sectionMessage .clientMessage .messageUser .userName p {
  color: #6b778c;
  margin: 0;
  font-size: 12px;
}

.viewOrders .sectionMessage .clientMessage .messageUser.messageTiming {
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .viewOrders .sectionMessage .clientMessage .messageUser.messageTiming {
    justify-content: flex-start;
  }
}

.viewOrders .sectionMessage .clientMessage .dropdown .dropdown-toggle {
  background: #fff;
  border: 0;
  box-shadow: 0 1px 1px 0 #dfe1e6;
}

.viewOrders .sectionMessage .clientMessage .dropdown .dropdown-toggle::after {
  display: none;
}

.viewOrders .sectionMessage .clientMessage .dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #00254d;
}

.viewOrders .sectionMessage .clientMessage .message-content {
  padding-left: 50px;
  margin-top: 10px;
}

@media (max-width: 575px) {
  .viewOrders .sectionMessage .clientMessage .message-content {
    padding-left: 0;
  }
}

.viewOrders .sectionMessage .clientMessage .message-content p {
  font-size: 14px;
  color: #201b50;
}

.viewOrders .sectionMessage .messageAttachment #addedfiles {
  display: flex;
  margin-bottom: 6px;
}

.viewOrders .sectionMessage .messageAttachment a {
  color: #201b50;
  display: block;
  font-size: 14px;
}

@media (max-width: 575px) {
  .viewOrders .sectionMessage .messageAttachment a {
    word-break: break-all;
  }
}

.viewOrders .sectionMessage .messageAttachment .innerIcon {
  color: #8d8d8d;
}

.viewOrders .sectionMessage .messageAttachment .innerIcon i {
  font-size: 13px;
}

.viewOrders .sectionMessage .messageAttachment .innerIcon.attachIcon {
  color: #bdbdbd;
}

.viewOrders .sectionMessage .reply-section {
  text-align: right;
  margin-top: 10px;
}

.viewOrders .sectionMessage .reply-section .btn {
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #42526e;
  font-size: 14px;
  background-color: #fff;
}

@media (max-width: 575px) {
  .viewOrders .sectionMessage .reply-section .btn {
    padding: 6px 5px;
    font-size: 13px;
  }
}

.viewOrders .sectionMessage .reply-section .btn i {
  margin-right: 10px;
  opacity: 0.7;
}

.viewOrders .sectionMessage .reply-section form {
  background-color: #fff;
  padding: 15px;
}

.viewOrders .sectionMessage .reply-section form textarea {
  border: 0;
  resize: none;
  margin-bottom: 10px;
}

.viewOrders .sectionMessage .reply-section form .delete {
  background: #fff;
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #201b50;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
}

.viewOrders .sectionMessage .reply-section form .send-btn {
  display: flex;
  justify-content: end;
}

@media (max-width: 767px) {
  .viewOrders .sectionMessage .reply-section form .send-btn {
    margin-top: 10px;
  }
}

.viewOrders .sectionMessage .reply-section form .send-btn .btn {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
  color: #fff;
  border-radius: 10px;
}

.viewOrders .sectionMessage .reply-section form .send-btn select {
  width: 200px;
  background: url(../img/arrow-down-blue.svg) #f3f3f7;
  color: #42526e;
  background-size: 9px;
  background-position: 96% 50%;
  background-repeat: no-repeat;
  box-shadow: none;
  margin-right: 10px;
  border-radius: 10px;
}

@media (max-width: 1366px) {
  .viewOrders .sectionMessage .reply-section form .send-btn select {
    width: 170px;
  }
}

@media (max-width: 767px) {
  .viewOrders .sectionMessage .reply-section form .send-btn select {
    width: 180px;
  }
}

.viewOrders .sectionMessage .reply-section .ccText {
  font-size: 13px;
  text-align: left;
  margin-top: 10px;
}

.viewOrders .sectionMessage .reply-section .ccText a {
  text-decoration: none;
}

.viewOrders .sectionMessage .reply-section .ccText a.ccIds {
  line-break: anywhere;
  margin-left: 5px;
}

.viewOrders .emojisection {
  text-align: center;
  margin-bottom: 20px;
}

.viewOrders .emojisection h4 {
  color: #00254d;
  margin-bottom: 20px;
}

.viewOrders .emojisection .emojibutton img {
  width: 100%;
  max-width: 40px;
}

.viewOrders .emojisection .emojibutton button {
  color: #0d6efd;
  font-size: 14px;
  border: 0;
  margin: 0 10px;
  background-color: transparent;
}

.viewOrders .section-history h3 {
  font-size: 21px;
  color: #201b50;
  font-weight: 600;
  margin-bottom: 20px;
}

.viewOrders .section-history .history-table {
  background-color: #fff;
  padding: 20px;
}

.viewOrders .section-history .history-table .table tr td {
  color: #6b778c;
  font-size: 14px;
}

@media (max-width: 575px) {
  .viewOrders .section-history .history-table .table tr td {
    font-size: 12px;
  }
}

.viewOrders .section-history .history-table .table tr td .clientName p {
  margin: 0;
  color: #201b50;
  font-size: 14px;
}

@media (max-width: 575px) {
  .viewOrders .section-history .history-table .table tr td .clientName p {
    font-size: 12px;
  }
}

.viewOrders .section-history .history-table .table tr td .clientName p span {
  background-color: #f3f3f7;
  color: #6b778c;
  padding: 5px;
}

.viewOrders .section-history .history-table .btnShowmore {
  display: block;
  text-align: center;
  text-decoration: none;
  border-top: 1px solid #5a4cdc;
  padding: 6px 0 0 0;
  color: #201b50;
}

@media (max-width: 575px) {
  .viewOrders .section-history .history-table .btnShowmore {
    font-size: 12px;
  }
}

.viewOrders .status-btns .status-btn {
  width: 120px;
  text-align: left;
  background-image: url(../img/arrow-down-blue.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 96% 50%;
}

@media (max-width: 575px) {
  .viewOrders .status-btns .status-btn {
    width: 88px;
  }
}

.viewOrders .status-btns .btn-follow {
  background-color: #fff;
  border: transparent;
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #201b50;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  margin-left: 8px;
}

.viewOrders .status-btns .btn-follow span {
  margin-right: 5px;
}

.viewOrders .status-btns .btn-follow.orderStatus {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 575px) {
  .viewOrders .status-btns .btn-follow.orderStatus {
    width: 32%;
  }
}

.viewOrders .status-btns select {
  background-color: #d4f5fa;
  color: #17a2b8;
  outline: none;
  border: 1px solid #d4f5fa;
  width: 150px;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  margin-left: 8px;
}

@media (max-width: 575px) {
  .viewOrders .status-btns select {
    width: 130px;
    margin-bottom: 10px;
  }
}

.viewOrders .status-btns .status-dropdown p {
  font-size: 11px;
  color: #6b778c;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 0 5px 6px 5px;
}

.viewOrders .status-btns .status-dropdown li a {
  font-size: 13px;
  display: flex;
  gap: 5px;
}

.viewOrders .status-btns .status-dropdown li a small {
  color: #6b778c;
}

.viewOrders .status-btns .status-dropdown li a .circle {
  margin-top: 5px;
}

.viewOrders .status-btns .status-dropdown li a .check-mark {
  margin-top: 4px;
}

.viewOrders .status-btns .status-dropdown.assignDropdown li .dropdown-item {
  padding: 0 20px;
}

.viewOrders .status-btns .status-dropdown.assignDropdown li .dropdown-item .check-mark {
  position: absolute;
  left: 4px;
}

.viewOrders .status-btns .ticketUsersDropdown {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

@media (max-width: 575px) {
  .viewOrders .status-btns .ticketUsersDropdown {
    width: 120px;
  }
}

.viewOrders .order-tags {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  margin-bottom: 15px;
}

.viewOrders .order-tags .form-group label {
  color: #201b50;
  font-weight: 700;
  font-size: 0.81rem;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.viewOrders .order-tags .form-group .input-group .form-control {
  box-shadow: none;
  font-size: 14px;
}

.viewOrders .order-tags .form-group .input-group button {
  font-size: 14px;
}

.viewOrders .order-tags .form-group .css-b62m3t-container {
  padding: 0;
  border: 0;
}

.viewOrders .order-tags .form-group .css-b62m3t-container .css-1s2u09g-control {
  border-radius: 5px 0 0 5px;
}

.viewOrders .order-tags .dataDropdown {
  top: 37px;
  width: 86%;
}

@media (max-width: 1366px) {
  .viewOrders .order-tags .dataDropdown {
    width: 85%;
  }
}

@media (max-width: 1366px) {
  .viewOrders .order-tags .dataDropdown {
    width: 83%;
  }
}

.viewOrders .order-tags .tagsButton {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
  color: #fff;
}

.viewOrders .order-tags .added-tags a {
  background: #c5bff9;
  border: none;
  color: #5a4cdc;
  text-decoration: none;
  padding: 3px 4px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
  margin: 0 3px;
}

.viewOrders .order-tags .added-tags a .cencelTag {
  display: none;
  margin-left: 2px;
}

.viewOrders .order-tags .added-tags a .cencelTag i {
  font-size: 11px;
}

.viewOrders .order-tags .added-tags a:hover .cencelTag {
  display: inline-block;
}

.viewOrders .ticketInfo {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  margin-bottom: 15px;
}

.viewOrders .ticketInfo h4 {
  color: #201b50;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 0.81rem;
}

.viewOrders .ticketInfo p {
  font-size: 13px;
  margin-bottom: 10px;
  color: #6b778c;
}

@media (max-width: 575px) {
  .viewOrders .ticketInfo p {
    word-break: break-all;
  }
}

.viewOrders .ticketInfo p a {
  text-decoration: none;
}

.viewOrders .ticketInfo p.infoText {
  color: #201b50;
}

@media (max-width: 575px) {
  .viewOrders .ticketInfo p.infoText {
    word-break: break-all;
  }
}

.viewOrders .ticketInfo p.infoText a {
  text-decoration: none;
  color: #201b50;
}

.viewOrders .ticketInfo ul {
  padding: 0;
}

.viewOrders .ticketInfo ul li {
  display: block;
  list-style-type: none;
}

.viewOrders .ticketInfo ul li a {
  text-decoration: none;
  color: #201b50;
}

.viewOrders .RecentTickets {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
}

.viewOrders .RecentTickets .accordion .accordion-item {
  background-color: transparent;
}

.viewOrders .RecentTickets .accordion .accordion-item .accordion-header .accordion-button {
  background-color: transparent;
  color: #201b50;
  font-size: 0.81rem;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: none;
}

.viewOrders .RecentTickets .accordion .accordion-body .ticketList a {
  font-size: 14px;
  text-decoration: none;
  color: #201b50;
}

.viewOrders .RecentTickets .accordion .accordion-body .ticketList p {
  color: #6b778c;
  font-size: 14px;
}

.history-modal .modal-header .modal-title {
  color: #201b50;
  font-size: 18px;
  font-weight: 600;
}

.history-modal .modal-header .btn-close {
  background-size: 11px;
}

.history-modal .modal-body .history-content .form-group label {
  color: #201b50;
  font-weight: 600;
  font-size: 14px;
}

.history-modal .modal-body .history-content .form-group label.checkbox-label {
  font-weight: 500;
}

.history-modal .modal-body .history-content .form-group label.checkbox-label small {
  color: #6b778c;
}

.history-modal .modal-body .history-content .form-group p {
  font-size: 14px;
  color: #00254d;
  margin-bottom: 0;
}

.history-modal .modal-body .history-content .form-group p a {
  text-decoration: none;
}

.history-modal .modal-body .history-content .form-group select {
  background: url(../img/arrow-down-blue.svg);
  background-size: 9px;
  background-position: 96% 50%;
  background-repeat: no-repeat;
  color: #00254d;
  font-size: 14px;
  box-shadow: none;
}

.history-modal .modal-body .history-content .form-group small {
  font-size: 11px;
}

.history-modal .modal-body .history-content.shareModal-content .form-group .input-group {
  gap: 10px;
}

.history-modal .modal-body .history-content.shareModal-content .form-group .input-group .form-control {
  border-radius: 5px;
}

.history-modal .modal-body .history-content.shareModal-content .form-group button {
  background-color: #f3f3f7;
  border: 1px solid #f3f3f7;
  color: #42526e;
  font-size: 14px;
  border-radius: 5px !important;
}

.history-modal .modal-body .history-content.shareModal-content .form-group small {
  color: #6b778c;
}

.history-modal .modal-body .history-content.shareModal-content .form-group small a {
  text-decoration: none;
}

.history-modal .modal-body .delete-content p {
  color: #201b50;
  font-size: 14px;
}

.history-modal .modal-footer button {
  font-size: 14px;
  border-radius: 10px;
}

.history-modal .modal-footer button.btn-secondary {
  background: #f3f3f7;
  border: 1px solid #f3f3f7;
  color: #42526e;
}

.history-modal .modal-footer button.btn-primary {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
}

.history-modal .modal-footer button.btn-primary:hover {
  background-color: #7b6fe2;
  border-color: #7b6fe2;
}

.section-setting .setting-content {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}

@media (max-width: 575px) {
  .section-setting .setting-content .setting-inner {
    margin-top: 10px;
  }
}

.section-setting .setting-content .setting-inner h4 {
  color: #00254d;
  font-size: 18px;
  font-weight: 600;
}

.section-setting .setting-content .setting-inner .setting {
  min-height: 70px;
  margin-top: 20px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .section-setting .setting-content .setting-inner .setting {
    min-height: auto;
  }
}

.section-setting .setting-content .setting-inner .setting a {
  text-decoration: none;
  color: #201b50;
  font-size: 14px;
}

.section-setting .setting-content .setting-inner .setting p {
  font-size: 13px;
  color: #6b778c;
  margin: 0;
}

.setting-tab {
  background-color: #fff;
  border-bottom: 1px solid #ebecf0;
}

.setting-tab .tabs-content .nav {
  justify-content: center;
}

@media (max-width: 767px) {
  .setting-tab .tabs-content .nav {
    justify-content: flex-end;
  }
}

.setting-tab .tabs-content .nav .nav-item .nav-link {
  background-color: transparent;
  color: #6b778c;
  font-size: 14px;
  outline: none;
}

.setting-tab .tabs-content .nav .nav-item .nav-link.active {
  border-bottom: 3px solid #5a4cdc;
  border-radius: 0;
  color: #42526e;
}

.setting-form-section .setting-form .tags-heading {
  display: flex;
  justify-content: space-between;
}

.setting-form-section .setting-form .tags-heading a {
  background-color: #5a4cdc;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  padding: 7px 10px;
  border-radius: 10px;
}

.setting-form-section .setting-form .tags-heading .dropdown .dropdown-menu li a {
  background-color: #fff;
  color: #201b50;
}

.setting-form-section .setting-form .commonForm {
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .setting-form-section .setting-form .commonForm {
    padding: 20px;
  }
}

.setting-form-section .setting-form .commonForm .statuses {
  border-bottom: 1px dashed #f3f3f3;
  padding: 7px;
}

.setting-form-section .setting-form .commonForm .statuses p {
  font-size: 12px;
  color: #6b778c;
  margin-bottom: 0;
  line-height: 25px;
}

.setting-form-section .setting-form .commonForm .statuses p span {
  padding: 1px 13px;
  border-radius: 5px;
  font-size: 12px;
}

.setting-form-section .setting-form .commonForm .statuses p span.grey {
  background-color: #d3d8e1;
}

.setting-form-section .setting-form .commonForm .statuses p span.submit {
  color: #28a745;
  background-color: #d8f6df;
}

.setting-form-section .setting-form .commonForm .statuses p span.info {
  color: #0dcaf0;
  background-color: #d4f8ff;
}

.setting-form-section .setting-form .commonForm .statuses p span.response {
  color: #cf8c12;
  background: #ffda97;
}

.setting-form-section .setting-form .commonForm .statuses p span.red {
  color: #f00;
  background: #ffb6b6;
}

.setting-form-section .setting-form .settingForm .form-group .form-check p {
  color: #6b778c;
  font-size: 12px;
}

.setting-form-section .setting-form .btn-section {
  margin: 20px 0;
}

.setting-form-section .setting-form .btn-section button {
  background-color: #5a4cdc;
  color: #fff;
  font-size: 14px;
  padding: 7px 30px;
  border-radius: 10px;
}

.setting-form-section .setting-form .btn-section button:hover {
  background-color: #7b6fe2;
  border-color: #7b6fe2;
}

@media (max-width: 1024px) {
  .setting-form-section .setting-form .btn-section button {
    padding: 7px 9px;
  }
}

.setting-form-section .setting-form .btn-section .btn-reset {
  background-color: #fff;
  color: #5a4cdc;
  border-color: #5a4cdc;
  margin-right: 10px;
}

.setting-form-section .setting-form .btn-section .btn-reset:hover {
  color: #fff;
}

.setting-form-section .setting-form .previews-btn {
  margin: 20px 0;
}

.setting-form-section .setting-form .previews-btn button {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #42526e;
  border-radius: 0;
  font-size: 14px;
}

@media (max-width: 1280px) {
  .setting-form-section .setting-form .previews-btn button {
    padding: 5px 8px;
  }
}

@media (max-width: 1024px) {
  .setting-form-section .setting-form .previews-btn button {
    width: 100px;
  }
}

.setting-form-section .setting-form h3 {
  color: #201b50;
  font-size: 21px;
  font-weight: 600;
  margin: 15px 0;
}

.setting-form-section .setting-form h3.emailHeading {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .setting-form-section .setting-form h3.emailHeading {
    display: block;
  }
}

@media (max-width: 575px) {
  .setting-form-section .setting-form h3.emailHeading .heading-btns {
    margin-top: 10px;
  }
}

.setting-form-section .setting-form h3.emailHeading .heading-btns a {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #42526e;
  padding: 6px 9px;
}

.setting-form-section .setting-form h3.emailHeading .heading-btns a.inactive {
  opacity: 0.65;
}

@media (max-width: 767px) {
  .setting-form-section .setting-form .team-table {
    overflow: auto;
  }
}

.setting-form-section .setting-form .table {
  border: 1px solid #ededed;
  margin-bottom: 5px;
}

.setting-form-section .setting-form .table tr td {
  border-bottom: 1px solid #ededed;
  padding: 15px 20px;
  font-size: 14px;
}

.setting-form-section .setting-form .table tr td a {
  text-decoration: none;
  color: #201b50;
}

.setting-form-section .setting-form .table tr td .dropdown {
  text-align: right;
}

.setting-form-section .setting-form .table tr td .dropdown button {
  background-color: transparent;
  border: 0;
  color: #42526e;
  padding: 0;
}

.setting-form-section .setting-form .table tr td .dropdown button::after {
  display: none;
}

.setting-form-section .setting-form .table tr td .dropdown .dropdown-menu {
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
  border: none;
}

.setting-form-section .setting-form .table tr td .dropdown .dropdown-menu li a {
  color: #00254d;
  font-size: 14px;
}

.setting-form-section .setting-form .table tr td .team-detail {
  display: flex;
  align-items: center;
  gap: 10px;
}

.setting-form-section .setting-form .table tr td .team-detail .img img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .setting-form-section .setting-form .table tr td .team-detail .img img {
    width: 40px;
    height: 40px;
  }
}

.setting-form-section .setting-form .table tr td .team-detail .name p {
  font-size: 13px;
  color: #6b778c;
  margin: 0;
}

.setting-form-section .setting-form .table tr td .team-detail .name p.userName {
  color: #201b50;
}

.setting-form-section .setting-form .table tr td .userRoles p {
  margin: 0;
  font-size: 13px;
}

.setting-form-section .setting-form .table tr td .userRoles p span {
  color: #6b778c;
  background-color: #f3f3f7;
  margin-left: 10px;
  display: inline-block;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 5px;
}

.setting-form-section .setting-form .table tr:last-child td {
  border: none;
}

.setting-form-section .setting-form .add-role {
  text-align: right;
}

.setting-form-section .setting-form .add-role a {
  color: #5a4cdc;
  text-decoration: none;
  font-size: 14px;
}

.setting-form-section .setting-form .common-table .table {
  border: 0;
}

.setting-form-section .setting-form .common-table .table tr th {
  border-color: #ebecf0;
}

.setting-form-section .setting-form .common-table .table tr td {
  padding: 0.5rem 0.5rem;
}

.setting-form-section .setting-form .common-table .table tr td .iconDel {
  background: #f3f3f7;
  color: #42526e;
  display: inline-flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.setting-form-section .setting-form.invoiceForm .lp-copy-sel__control {
  margin: 0;
}

.setting-form-section .setting-form .addTeam-btn {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 6px 20px;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}

.setting-form-section .setting-form .addTeam-btn:hover {
  background-color: #7b6fe2;
  border-color: #7b6fe2;
}

.setting-form-section .setting-form.ticketForm .messageAttachment a {
  color: #201b50;
  font-size: 13px;
  text-decoration: none;
}

.setting-form-section .couponForm .form-group .input-group .form-control {
  border-radius: 10px !important;
}

.setting-form-section .couponForm .form-group .input-group .btn-generate {
  background-color: #f3f3f7;
  border: 1px solid #f3f3f7;
  color: #201b50;
  border-radius: 10px !important;
  font-size: 14px;
  margin-left: 10px !important;
  padding: 0 10px;
  height: 40px;
}

.setting-form-section .couponForm .form-group a.discountLink {
  text-decoration: none;
  display: block;
  text-align: right;
  font-size: 14px;
}

.setting-form-section .couponForm .form-group .css-26l3qy-menu {
  z-index: 999;
}

@media (max-width: 767px) {
  .setting-form-section .couponForm .addMore-discount .row {
    margin-bottom: 5px;
  }
}

.setting-form-section .couponForm .addMore-discount .row .form-group {
  margin: 0;
}

.setting-form-section .couponForm .addMore-discount .row .form-group label {
  display: none;
}

@media (max-width: 767px) {
  .setting-form-section .couponForm .addMore-discount .row .form-group label {
    display: inline;
  }
}

.setting-form-section .couponForm .addMore-discount .row:first-child .form-group label {
  display: inline-block;
}

.setting-form-section .couponForm .expiryDate {
  background-image: url(../img/calendar.png);
  background-size: 16px;
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

.setting-form-section .btn-payment {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
  color: #fff;
  border-radius: 10px;
}

.setting-form-section .btn-payment:hover {
  background-color: #7b6fe2;
  border-color: #ced4da;
}

.setting-form-section .invoiceNote {
  color: #201b50;
  margin: 8px 0;
  font-size: 14px;
  font-style: italic;
}

.setting-form-section .history-table .btnShowmore {
  display: block;
  text-align: center;
  text-decoration: none;
  border-top: 1px solid #5a4cdc;
  padding: 6px 0 0 0;
  color: #201b50;
  font-size: 16px;
}

.tags-modal .modal-footer button {
  font-size: 14px;
}

.tags-modal .modal-footer button.btn-primary {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
}

.tags-modal .modal-footer button.btn-secondary {
  background-color: #f3f3f7;
  border-color: #f3f3f7;
  color: #42526e;
}

.invoiceBytext {
  color: #6b778c;
  font-size: 13px;
  margin-top: 5px;
}

.invoiceBytext a {
  text-decoration: none;
  color: #007bff;
}

@media (max-width: 991px) {
  .clients-table {
    overflow: auto;
  }
  .clients-table .table tr td {
    white-space: nowrap;
  }
}

@media (max-width: 575px) {
  .clients-table {
    overflow: auto;
  }
}

.client-heading .userImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.client-heading .userName {
  margin-left: 10px;
}

.client-heading .userName h3 {
  color: #201b50;
  font-weight: 600;
}

.client-heading .btns {
  display: flex;
  justify-content: flex-end;
}

.client-heading .btns a.btn-edit {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #42526e;
  text-decoration: none;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
}

.client-heading .btns a.btn-edit:hover {
  background: #fafafd;
  border: 1px solid #fafafd;
  color: #42526e;
}

.client-heading .btns .dropdown .dropdown-toggle {
  background: #fff;
  border: 0;
  box-shadow: 0 1px 1px 0 #dfe1e6;
  color: #42526e;
  margin-left: 10px;
}

.client-heading .btns .dropdown .dropdown-toggle::after {
  display: none;
}

.client-heading .btns .dropdown .dropdown-menu {
  border: none;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
}

.client-heading .btns .dropdown .dropdown-menu li a {
  color: #00254d;
  font-size: 14px;
}

.client-details {
  background-color: #fff;
  padding: 1.25rem;
  margin-top: 20px;
}

.client-details .invoiceHeading {
  color: #00254d;
}

.client-details .subsInfo {
  font-size: 16px;
  font-weight: 600;
  color: #201b50;
  margin-bottom: 20px;
}

.client-details p {
  font-size: 14px;
}

.client-details p.clientLabel {
  color: #6b778c;
}

.client-details p.clientText {
  color: #201b50;
}

.client-details p.clientText a {
  text-decoration: none;
  color: #5a4cdc;
}

.client-details p.teamRule-text {
  color: #007bff;
  cursor: pointer;
  text-transform: capitalize;
}

.client-details .btn-account {
  background-color: #f3f3f7;
  color: #42526e;
  font-size: 14px;
}

.client-details .btn-account:hover {
  background-color: #f3f3f7;
}

.client-details .table {
  margin: 0;
}

.client-details .table tr th {
  color: #6b778c;
  font-size: 14px;
  font-weight: 500;
}

.client-details .table tr td {
  color: #201b50;
  font-size: 14px;
}

.client-details .table tr td a {
  text-decoration: none;
}

.client-details .table tr:last-child td {
  border-bottom: 0;
}

.client-details .invoiceText {
  color: #00254d;
  font-size: 1.125rem;
  font-weight: 600;
}

@media (max-width: 575px) {
  .client-details .mobileSpace {
    margin-top: 20px;
  }
}

.history-table {
  background-color: #fff;
  padding: 20px 20px;
  margin-bottom: 15px;
}

.history-table .history-date p {
  color: #6b778c;
  font-size: 14px;
}

.history-table .history-detail p {
  color: #201b50;
  font-size: 14px;
}

.history-table .history-detail p span {
  font-weight: 600;
  background-color: #f3f3f7;
  padding: 3px 4px;
  border-radius: 5px;
  color: #6b778c;
}

.notes {
  padding: 20px;
  border-left: 3px solid #ffc107;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}

.notes .edid-note {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.notes .edid-note a {
  text-decoration: none;
}

.notes p {
  width: 90%;
}

.payments .payment-table {
  background-color: #fff;
}

.payments .payment-table .table tr th {
  font-size: 14px;
  color: #6b778c;
  text-transform: uppercase;
  font-weight: 500;
}

.payments .payment-table .table tr td {
  color: #201b50;
  font-size: 14px;
}

.payments .payment-table .table tr td a {
  text-decoration: none;
  color: #5a4cdc;
}

.orders-section .common-table.files-table .table tr td {
  color: #201b50;
}

.orders-section .common-table.files-table .table tr td a {
  color: #201b50;
  text-decoration: none;
}

.orders-section .common-table.files-table .table tr:last-child td {
  border-bottom: 0;
}

.orders-section .common-table.files-table .table tr .dropdown-menu li a {
  color: #00254d;
}

@media (max-width: 767px) {
  .orders-section .common-table.TableOrders {
    overflow: auto;
  }
  .orders-section .common-table.TableOrders .table tr td,
  .orders-section .common-table.TableOrders .table tr th {
    white-space: nowrap;
  }
}

.couponHeading {
  display: flex;
  justify-content: space-between;
}

.service-pricingForm .nav-tabs .nav-item .nav-link {
  background-color: rgba(255, 255, 255, 0.5);
  color: #6b778c;
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 0;
  font-size: 14px;
  border-radius: 0.25rem 0.25rem 0 0;
  margin-right: 5px;
}

.service-pricingForm .nav-tabs .nav-item .nav-link.active {
  background-color: #fff;
  color: #00254d;
}

.service-pricingForm a {
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

.service-pricingForm .form-control {
  font-size: 14px;
}

.service-pricingForm .alert-warning {
  border-left: 6px solid #ffc107;
}

.intakeForm p {
  color: #00254d;
  font-size: 14px;
}

.intakeForm p span {
  background-color: #f3f3f7;
  font-weight: 600;
  color: #6b778c;
  padding: 3px 6px;
  border-radius: 4px;
}

.intakeForm .form-group .Upldimages {
  text-align: center;
  position: relative;
  border: 3px dashed #f3f3f7;
  padding: 20px 0;
}

.intakeForm .form-group .Upldimages input {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
}

.intakeForm .form-group .Upldimages i {
  color: #dbdbdb;
}

.intakeForm .form-group .Upldimages p {
  color: #6b778c;
  margin-top: 8px;
}

.intakeForm .form-group .Upldimages p span {
  color: #201b50;
  background-color: transparent;
  padding: 0;
  font-weight: 500;
}

.previewImg {
  margin-top: 20px;
}

.previewImg .ImgSection {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}

.previewImg .ImgSection img {
  width: 70px;
  height: 70px;
}

.previewImg .ImgSection .Del {
  position: absolute;
  background-color: #e7e7e7;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  top: -10px;
  right: -10px;
  z-index: 1;
  cursor: pointer;
}

.previewImg .ImgSection .Del i {
  color: #00254d;
  font-size: 13px;
}

.addItem {
  font-size: 14px;
  text-decoration: none;
  display: block;
  text-align: right;
  margin-bottom: 10px;
}

.recurring-section {
  margin-top: 10px;
}

.recurring-section label {
  margin: 0 10px 0 0;
}

.recurring-section .form-control {
  border-radius: 5px 0 0 5px !important;
}

.recurring-section select {
  border: 0;
  background-color: #e9ecef;
  outline: none;
  height: 26px;
}

.dashboardSection .dashboardFilters {
  text-align: right;
}

.dashboardSection .dashboardFilters .dropdown {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .dashboardSection .dashboardFilters .dropdown {
    display: block;
  }
}

.dashboardSection .dashboardFilters .dropdown .dropdown-toggle {
  color: #201b50;
  background-color: #fff;
  box-shadow: 5px 5px 20px #dfe1e6;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .dashboardSection .dashboardFilters .dropdown .dropdown-toggle {
    margin: 0 0 10px 0;
  }
}

.dashboardSection .dashboardFilters .dropdown .btn-primary {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
}

.dashboardSection .dashboardFilters .dropdown .form-control {
  width: 200px;
  margin-left: 10px;
  border-color: #fff;
  box-shadow: none;
  font-size: 14px;
}

@media (max-width: 767px) {
  .dashboardSection .dashboardFilters .dropdown .form-control {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.dashboardSection .dashboardFilters .dropdown .dashboardDropdown {
  background-image: url(../img/arrow-down-blue.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 95% 50%;
  width: 110px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .dashboardSection .dashboardFilters .dropdown .dashboardDropdown {
    width: 48%;
    margin-right: 3px;
  }
}

.dashboardSection .dashboardFilters .react-datepicker {
  position: absolute;
  right: 50px;
  top: 40px;
  z-index: 11;
}

@media (max-width: 575px) {
  .dashboardSection .dashboardFilters .react-datepicker {
    top: 90px;
  }
}

.dashboardSection .cartSection {
  background-color: #fff;
  padding: 20px 0;
}

.dashboardSection .cartSection .recharts-wrapper {
  width: 100% !important;
}

@media (max-width: 767px) {
  .dashboardSection .cartSection .recharts-wrapper {
    height: 150px !important;
  }
}

.dashboardSection .cartSection .recharts-wrapper .recharts-surface {
  width: 100%;
}

@media (max-width: 767px) {
  .dashboardSection .cartSection .recharts-wrapper .recharts-surface {
    height: 150px !important;
  }
}

.dashboardSection .cartSection .recharts-wrapper .recharts-legend-wrapper {
  display: none;
}

.dashboardSection .cartSection .recharts-wrapper .recharts-layer path {
  fill: #8884d8;
}

.dashboardSection .tile {
  background-color: #fff;
  padding: 25px 25px;
  box-shadow: 5px 5px 20px #dfe1e6;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #201b50;
  cursor: pointer;
}

@media (max-width: 767px) {
  .dashboardSection .tile {
    margin-bottom: 10px;
  }
}

.dashboardSection .tile .tileImg {
  transition: all 0.3s ease-in-out;
}

.dashboardSection .tile .tileImg img {
  width: 100%;
  height: 60px;
  max-width: 60px;
  object-fit: contain;
}

@media (max-width: 991px) {
  .dashboardSection .tile .tileImg {
    margin-bottom: 10px;
  }
}

.dashboardSection .tile p {
  font-size: 17px;
  color: #6b778c;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.dashboardSection .tile h3 {
  color: #201b50;
  font-size: 25px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.dashboardSection .tile:hover .tileImg {
  transform: translate(0, -4px);
}

.dashboardSection .card .card-header {
  background-color: #fff;
  padding: 0.8rem;
}

.dashboardSection .card .card-header h3 {
  font-size: 24px;
  color: #201b50;
  font-weight: 600;
}

@media (max-width: 575px) {
  .dashboardSection .card .card-header h3 {
    font-size: 18px;
  }
}

.dashboardSection .card .card-body .recharts-wrapper {
  width: 100% !important;
}

@media (max-width: 767px) {
  .dashboardSection .card .card-body .recharts-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
}

.dashboardSection .card .card-body .recharts-wrapper svg {
  width: 100% !important;
}

@media (max-width: 767px) {
  .dashboardSection .card .card-body .recharts-wrapper svg {
    width: 100% !important;
    height: 100% !important;
  }
}

@media (max-width: 767px) {
  .dashboardSection .card .card-body .recharts-wrapper .recharts-tooltip-wrapper {
    display: none;
  }
}

.dashboardSection .card .card-body .recharts-wrapper .recharts-default-legend {
  display: none;
}

.listingSection .common-table {
  background-color: #fff;
}

.listingSection .common-table .table tr th {
  color: #201b50;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.listingSection .common-table .table tr td {
  color: #6b778c;
  font-size: 14px;
}

.listingSection .common-table .table tr td a {
  text-decoration: none;
  color: #201b50;
}

.listingSection .common-table .table tr td small {
  color: #6b778c;
}

.listingSection .common-table .table tr:last-child td {
  border-bottom: 0;
}

.sectionHome {
  padding: 50px 0;
}

.sectionHome .homeTiles {
  margin-top: 30px;
}

.sectionHome .homeTiles .tileText {
  padding: 10px;
}

.sectionHome .homeTiles .tileText p {
  color: #6b778c;
  font-size: 14px;
}

.sectionHome .homeTiles .tileText h3 {
  color: #00254d;
  font-weight: 600;
}

.sectionHome .homeTiles .tile {
  background-color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #201b50;
  padding: 25px 25px;
}

@media (max-width: 767px) {
  .sectionHome .homeTiles .tile {
    margin-bottom: 10px;
  }
}

.sectionHome .homeTiles .tile .tileImg {
  transition: all 0.3s ease-in-out;
}

.sectionHome .homeTiles .tile .tileImg img {
  width: 100%;
  max-width: 70px;
  height: 70px;
  object-fit: contain;
}

.sectionHome .homeTiles .tile:hover .tileImg {
  transform: translate(0, -4px);
}

.shopSection .heading h2 {
  display: flex;
  justify-content: space-between;
}

.shopSection .heading .btn-primary {
  background-color: #fff;
  color: #5a4cdc;
  border-color: #5a4cdc;
  border-radius: 10px;
}

.shopSection .heading .btn-primary:hover {
  background-color: #5a4cdc;
  color: #fff;
}

.shopSection .card {
  border-radius: 5px;
  border: 0;
  min-height: 500px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

@media (max-width: 767px) {
  .shopSection .card {
    min-height: auto;
  }
}

.shopSection .card:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.shopSection .card .serviceImg {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

@media (max-width: 1366px) {
  .shopSection .card .serviceImg {
    height: 250px;
  }
}

.shopSection .card .card-body h3 {
  color: #00254d;
  font-size: 19px;
  font-weight: 600;
  text-transform: capitalize;
}

.shopSection .card .card-body .servicesContent p {
  margin: 0;
}

.shopSection .card .card-body p {
  color: #00254d;
  font-size: 14px;
}

.shopSection .card .card-body ul li {
  color: #00254d;
  font-size: 14px;
}

.shopSection .card .card-footer {
  background-color: #fff;
}

.shopSection .card .card-footer p {
  margin: 0;
  color: #201b50;
  font-size: 17px;
  font-weight: 600;
}

.shopSection .card .card-footer .btn-primary {
  font-size: 14px;
  background-color: #5a4cdc;
  border-color: #5a4cdc;
  border-radius: 10px;
}

.shopSection .card .card-footer .btn-primary:hover {
  background-color: #7b6fe2;
  border-color: #7b6fe2;
}

.shopSection .pagination {
  margin-top: 15px;
}

.shopSection .pagination .page-item .page-link {
  color: #201b50;
}

.shopSection .pagination .page-item .page-link:hover {
  background-color: #201b50;
  color: #fff;
  border-color: #201b50;
}

.shopSection .pagination .page-item .page-link:focus {
  box-shadow: none;
}

.shopSection .pagination .page-item.active .page-link {
  background-color: #201b50;
  border-color: #201b50;
  color: #fff;
}

@media (max-width: 575px) {
  .cart-modal {
    padding: 0 !important;
  }
}

@media (max-width: 575px) {
  .cart-modal .cart-content {
    overflow: auto;
  }
}

.cart-modal .cart-content .table tr td .cartQty {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.cart-modal .cart-content .table tr td .cartQty input {
  width: 60px;
  border: 1px solid #d1d1d1;
  height: 35px;
  outline: none;
  padding: 10px;
}

.cart-modal .cart-content .table tr td .cartQty a {
  text-decoration: none;
  font-size: 14px;
}

.cart-modal .cart-content .table tr td .input-group .form-control {
  box-shadow: none;
  font-size: 14px;
}

.cart-modal .cart-content .table tr td .input-group .input-group-text {
  background-color: #201b50;
  border-color: #201b50;
  color: #fff;
}

@media (max-width: 575px) {
  .cart-modal .cart-content .table tr td {
    white-space: nowrap;
  }
}

.cart-modal .cart-content .table tr:last-child td {
  border: 0;
}

.cart-modal .cart-content .errorMessage {
  background-color: #f2d1d1;
  color: #f30d0d;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
}

.cart-modal .cart-content .successMsg {
  color: #138c2f;
  background-color: #d4edda;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  margin-top: 10px;
}

.cart-modal .modalBtns {
  margin-top: 20px;
}

.cart-modal .modalBtns a {
  text-decoration: none;
  font-size: 14px;
}

.cart-modal .modalBtns .btn-primary {
  font-size: 14px;
}

.cart-modal.viewCart-section .cart-content {
  background-color: #fff;
  padding: 20px;
}

.cart-modal .btn-primary {
  background-color: #5a4cdc;
  border-color: #5a4cdc;
}

.cart-modal .btn-primary:hover {
  background-color: #7b6fe2;
  border-color: #7b6fe2;
}

@media (max-width: 991px) {
  .checkOut-page .h-100vh {
    height: auto;
  }
}

@media (max-width: 991px) {
  .checkOut-page .container-fluid {
    padding-right: 15px !important;
  }
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing {
    margin-top: 10px !important;
  }
}

@media (max-width: 991px) {
  .checkOut-page .checkoutListing {
    padding: 0;
  }
}

.checkOut-page .checkoutListing p {
  color: #201b50;
  font-size: 14px;
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing p {
    font-size: 12px;
    margin-bottom: 7px;
  }
}

.checkOut-page .checkoutListing p a {
  text-decoration: none;
}

.checkOut-page .checkoutListing p.agencyName {
  color: #6b778c;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing p.agencyName {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .checkOut-page .checkoutListing p.agencyName {
    margin: 20px 0;
  }
}

.checkOut-page .checkoutListing h5 {
  color: #201b50;
  font-size: 15px;
  margin-bottom: 15px;
}

.checkOut-page .checkoutListing h3 {
  color: #201b50;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
}

.checkOut-page .checkoutListing .billingRow {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.checkOut-page .checkoutListing .billingRow .billingAddress p {
  margin: 0;
}

.checkOut-page .checkoutListing .sectionHelp {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkOut-page .checkoutListing .sectionHelp p {
  color: #6b778c;
  margin: 0;
}

.checkOut-page .checkoutListing .sectionHelp .continueBtn {
  background-color: #5a4cdc;
  color: #fff;
  padding: 7px 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.checkOut-page .checkoutListing .sectionHelp .continueBtn:hover {
  background-color: #7b6fe2;
}

.checkOut-page .checkoutListing form h4 {
  color: #201b50;
  font-size: 16px;
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing form h4 {
    font-size: 14px;
  }
}

.checkOut-page .checkoutListing form .form-group .form-control {
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 10px;
  box-shadow: none;
  background-color: #fff;
  font-size: 14px;
  height: 40px;
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing form .form-group .form-control {
    height: 33px;
  }
}

.checkOut-page .checkoutListing form .form-group select {
  background: url(../img/arrow-down-blue.svg);
  background-size: 11px;
  background-position: 98% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.checkOut-page .checkoutListing form .form-group p {
  color: #6b778c;
}

.checkOut-page .checkoutListing form .form-group .form-check-label {
  color: #201b50;
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing form .form-group .form-check-label {
    font-size: 14px;
  }
}

.checkOut-page .checkoutListing form .accordion-button:not(.collapsed) {
  background-color: #fff;
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing form .accordion-button {
    padding: 6px 20px;
  }
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing form .accordion-button img {
    width: 100%;
    max-width: 110px;
  }
}

.checkOut-page .checkoutListing form .accordion-button::after {
  display: none;
}

.checkOut-page .checkoutListing form .accordion .accordion-item {
  border-radius: 0 0 0.375rem 0.375rem;
}

.checkOut-page .checkoutListing form .paymentOption {
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid #dee2e6;
  border-bottom: 0;
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing form .paymentOption {
    padding: 6px 20px;
  }
}

.checkOut-page .checkoutListing form .paymentOption .form-check-label img {
  width: 100%;
  max-width: 100px;
}

.checkOut-page .checkoutListing form .paymentOption:last-child {
  border-radius: 0 0 0.375rem 0.375rem;
}

.checkOut-page .checkoutListing form .paymentOption .existingCard img {
  width: 100%;
  max-width: 30px;
}

.checkOut-page .checkoutListing form .paymentOption .expDel a {
  color: #201b50;
}

.checkOut-page .checkoutListing form .paymentOption .expDel a i {
  font-size: 14px;
  margin-left: 10px;
}

.checkOut-page .checkoutListing form .paymentOption .cardExpire {
  font-size: 14px;
  background-color: #f3f3f7;
  color: #201b50;
  padding: 2px 10px;
  border-radius: 0.375rem;
}

@media (max-width: 1366px) {
  .checkOut-page .checkoutListing form .paymentOption .cardExpire {
    font-size: 12px;
  }
}

.checkOut-page .checkoutListing form .bnt-save {
  width: 100%;
  background-color: #5a4cdc;
  color: #fff;
  margin-top: 10px;
}

.checkOut-page .checkoutListing.formCheckout {
  height: 94vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.checkOut-page .checkoutListing.formCheckout::-webkit-scrollbar {
  display: none;
}

@media (max-width: 991px) {
  .checkOut-page .checkoutListing.formCheckout {
    height: auto;
  }
}

.checkOut-page .summaryHead {
  background-color: #201b50;
  display: grid;
  align-items: center;
  padding: 90px;
}

@media (max-width: 1366px) {
  .checkOut-page .summaryHead {
    padding: 40px;
  }
}

@media (max-width: 991px) {
  .checkOut-page .summaryHead {
    height: auto;
    margin-top: 10px;
  }
}

.checkOut-page .summaryHead .summaryPage h4 {
  color: #fff;
}

.checkOut-page .summaryHead .summaryPage .d-flex {
  justify-content: space-between;
}

.checkOut-page .summaryHead .summaryPage .d-flex p {
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.checkOut-page .summaryHead .summaryPage .d-flex p.totalPrice {
  font-size: 18px;
}

.checkOut-page .summaryHead .summaryPage hr {
  border-color: #fff;
}

.lp-copy-sel__option,
.lp-copy-sel__single-value {
  white-space: pre-wrap !important;
}

.tooltipdatepicker {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltipdatepickertext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 510%;
  right: 300px;

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.tooltipdatepicker:hover .tooltipdatepickertext {
  visibility: visible;
  opacity: 1;
}